import { useCallback } from 'react';
import L from 'leaflet';
import {
  getTerminalStationsZoomAdjustment,
  getIntermediateStationsZoomAdjustment,
} from '../utils';

const useGetStationIcons = ({ color, zoomLevel, station1, station2 }) => {
  const generateStationIcon = useCallback(
    (size, color, _class) =>
      new L.DivIcon({
        className: 'icon-div',
        html: `<div style="width: ${size}; height: ${size}; border-color: ${color};" class="${_class}"/>`,
      }),
    []
  );

  const terminalStationIcon = generateStationIcon(
    getTerminalStationsZoomAdjustment(zoomLevel),
    color,
    'terminal-station-dot'
  );
  const intermediateStationIcon = generateStationIcon(
    getIntermediateStationsZoomAdjustment(zoomLevel),
    color,
    'station-dot'
  );

  return { terminalStationIcon, intermediateStationIcon };
};

export default useGetStationIcons;
